
@import "./boxicons.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import "./node_modules/bootstrap/scss/mixins.scss";

@import "custom/components";
@import "custom/plugins/switch";
@import "custom/plugins/responsive-table";
@import "custom/plugins/apexcharts";

*{
  font-family: $font-family-sans-serif
}
body{background-color: #fff;}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column{
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}


.close{
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .5;
    width: 1em;
    height: 1em;
    z-index: 2;
    span {
      display: none;
    }
  }
  .modal-content{border-radius:0}
  .modal-open{
    overflow-y: none !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
  .modal-open .modal {
    margin: 0 auto;
}
  .wizard .actions {
    position: relative !important;
    display: block !important;
    text-align: right !important;
    width: 100% !important;
}
.wizard .actions>ul {
  display: block !important;
  text-align: right !important;
  padding-left: 0 !important;
}
.wizard .actions>ul>li {
  display: inline-block !important;
  margin: 0 .5em !important;
}

//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,.rdw-editor-main{
  border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
  text-transform: capitalize;
}
.dropzone:focus {
  outline: none !important ;
}
//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}
.invalid-feedback {
  margin-top: -10px !important;
  margin-bottom: 13px !important;
}


.verti-timeline{
    border-left: 3px dashed $gray-300;
    margin: 0 10px;
    .event-list{
        position: relative;
        padding: 0px 0px 40px 30px;

        .event-timeline-dot{
            position: absolute;
            left: -9px;
            top: 0px;
            z-index: 9;
            font-size: 16px;
        }
        .event-content{
            position: relative;
            border: 2px solid $border-color;
            border-radius: 7px;
        }

        &.active{
            .event-timeline-dot{
                color: $primary;
            }
        }

        &:last-child{
            padding-bottom: 0px;
        }
    }
}



.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: 1px 1px 15px 1px rgba(235,236,240,0.6);
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

// Breadcrumb item arrow
.breadcrumb-item {
    > a {
        color: $gray-700;
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }
}

.noti-icon {    
    .badge {
        position: absolute;
        top: -8px;
        left: 5px;
    }
}


@keyframes showHide {
  0%   {opacity:0}
  30%  {opacity:0}
  100% {opacity:1}
}
@keyframes showHide2 {
  0%   {opacity:0}
  30%  {opacity:0}
  100% {opacity:1}
}


.img-upperview{
  animation-name: showHide2;
  animation-duration: 1.5s;
}
.img-sideview{
  animation-name: showHide;
  animation-duration: 1.5s;
}

.react-switch-bg{border: 1px solid rgb(204, 204, 204);}
.bg-custom-blue{background-color: #556ee6;}
.bg-custom-yellow{background-color: #f1b44c;}
.bg-custom-green{background-color: #34c38f;}

.login-img , .login-img > div{
  padding:0; 
  height:100vh;
  overflow:hidden;
  @media screen and (max-width: 767px) {
    position:absolute;
    top:0;
    z-index:0;
    min-height:100vh;    
  }
}

.login-bg-2{
  width:100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color:#fff;
  opacity: 0.9;
  img{
    margin:auto;
    max-width: 100%; 
    max-height: 100%;
    position: relative;
    top: 100%;
    transform: translateY(-100%);
  }
}

.logoutBtn:hover{background-color:transparent; color: var(--bs-body-color);};

.sensors{
  animation-name: showHide;
  animation-duration: 1.5s;
}

.sensor-placements{  
  position:absolute;
  cursor:pointer;  
  animation-name: rotation;
  animation-iteration-count: infinite; 
  animation-timing-function: linear;
  -webkit-animation-name: rotation;
  -webkit-animation-iteration-count: infinite; 
  -webkit-animation-timing-function: linear;
  background-color: $white;
  border-radius: 50%;
  border: 2px solid $info;
  margin-top:-22px;
  margin-left:-22px;
  @media (max-width: 1200px) and (min-width: 992px) {
      margin-top:-17px;
      margin-left:-17px;  
  }
  @media screen and (max-width: 992px) {
      margin-top:-12px;
      margin-left:-12px;  
  }
  &:hover{
    opacity:0.9;
  }
  svg{
    max-width:40px;
    max-height:40px;
    @media (max-width: 1200px) and (min-width: 992px) {
      max-width:30px;
      max-height:30px
    }
    @media screen and (max-width: 992px) {
      max-width:20px;
      max-height:20px
    }
  }

}

@-webkit-keyframes rotation {
    0%, 40%   {-webkit-transform: rotate(0deg);}
    60%, 100% {-webkit-transform: rotate(360deg);}
}
@keyframes rotation {
    0%, 40% { transform: rotate(0deg); }
    60%, 100% { transform: rotate(360deg); }
}

.sensor-orange path{fill:#F1B44C}
.sensor-red path{fill:#f46a6a}


.table-overflow-custom thead th{
  position: sticky;
  top: -1px;
  z-index: 1;
}

.alert-list{
  @media screen and (min-width: 768px) {
    overflow-Y:auto;
    min-height: 180px;
  }
}

.alert-list::-webkit-scrollbar, .table-overflow-custom::-webkit-scrollbar {
  width: 6px;
  border-radius:15px;
}

.alert-list::-webkit-scrollbar-track, .table-overflow-custom::-webkit-scrollbar-track {
  background: rgb(85,110,230, 0.25);
  border-radius:15px;
}

.alert-list::-webkit-scrollbar-thumb, .table-overflow-custom::-webkit-scrollbar-thumb {
  background: rgb(85,110,230, 0.7);
  border-radius:15px;
}



.col-xxl-6{
  @media (min-width: 1351px) {
      width:50%
  }
}

.profile-img{
  object-fit: cover;
  width: 100%;
  height: 100%; 
}

.btn-selected{
  background:#ccc !important;
  border-color:#ccc !important;
  box-shadow: none !important;
}

.map-marker-label-device{
  margin-top: 33px;
  font-size: 10px !important;
  color: #3c89f9 !important;
  background-color: rgba(255,255,255,0.85);
  padding: 2px 5px;
}
.map-marker-label-plant{
  margin-top: 33px;
  font-size: 10px !important;
  color: #34c38f !important;
  background-color: rgba(255,255,255,0.85);
  padding: 2px 5px;
}

@import "./custom.scss";